export interface FAQuestion {
  title: string;
  content: JSX.Element;
  // https://developers.google.com/search/docs/data-types/faqpage#answer
  seoDescription: string;
  key: HcpFAQuestionKey | PatientFAQuestionKey;
}

export enum HcpFAQuestionKey {
  Telemonitor,
  TelemonitorRegulations,
  Contraindications,
  CBTi,
  ArtificialIntelligence,
  Chatbot,
  FindTheApp,
  ForWhom,
  CBTiVsSleepHygiene,
  WhatContent,
  WhenHowToAddressInsomnia,
  HowLong,
}

export enum PatientFAQuestionKey {
  What,
  Effectiveness,
  Accuracy,
  Who,
  IUseMedication,
  HumanContact,
  Anxiety,
  Insomnia,
  Depression,
}
